import { Checkbox } from "@blueprintjs/core";
import { CustomCellRendererProps } from "ag-grid-react";
import { observer } from "mobx-react";

import { withObservedValueGetter } from "@utilities/AgGridUtils";

import styles from "./CheckboxCell.module.scss";

export interface ICheckboxCellOwnProps<T> {
  onToggleSelection(data: T): void;
}

interface ICheckboxCellProps extends CustomCellRendererProps<any, boolean>, ICheckboxCellOwnProps<any> {}

const CheckboxCell = (props: ICheckboxCellProps) => {
  const { value: selected, onToggleSelection } = props;
  const { data } = props.node;

  const toggleSelection = () => {
    onToggleSelection(data);
  };

  return <Checkbox onChange={toggleSelection} className={styles.checkboxCell} checked={!!selected} />;
};

export default withObservedValueGetter(observer(CheckboxCell));
