import { FocusEvent, KeyboardEvent, useState } from "react";
import { Icon, Menu } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { Text, TextVariant } from "@ui/Text";
import { CustomHeaderProps } from "ag-grid-react";
import { observer } from "mobx-react";

import { getColDefs } from "@components/BomTables/Table/utils";
import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { InputGroup } from "@components/InputGroup";
import { MenuItem } from "@components/MenuItem";
import HeaderStatusNav from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/HeaderStatusNav";
import { Popover } from "@components/Popover";
import { ENTER_KEY, ESCAPE_KEY } from "@constants/keys";
import appStore from "@store/AppStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";
import { ITableColumn, ITableColumnEntity } from "@store/TableColumnStore";
import { EntityType } from "@store/types";

import styles from "./RequirementsHeaderCellRenderer.module.scss";

export interface IRequirementsHeaderCellRendererOwnProps {
  reqPageId: string;
  tableColumn: ITableColumn;
}

interface IRequirementsHeaderCellRendererProps extends IRequirementsHeaderCellRendererOwnProps, CustomHeaderProps {}

const RequirementsHeaderCellRenderer = (props: IRequirementsHeaderCellRendererProps) => {
  const { displayName, tableColumn, reqPageId, column, api } = props;
  const reqPage = appStore.workspaceModel?.requirementsModule.get(reqPageId);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const workspace = useWorkspace();
  const tableProxy = workspace.bomTablesMap.get(appStore.env.activeBomTableId || "");

  if (!reqPage) {
    return null;
  }

  const { entity } = tableColumn;
  const isCustomColumn = !!entity;

  const handleDeleteDefinition = () => {
    reqPage.removeColumn(tableColumn.id);
    const { entity } = tableColumn;
    if (entity?.type === EntityType.StatusDefinition) {
      const statusDefinition = workspace.statusDefinitionMap.get(entity.id);
      if (statusDefinition) {
        workspace.deleteStatusDefinition(statusDefinition);
      }
    }
    setShowConfirmation(false);
  };

  const handleStatusTypeChange = () => {
    if (tableProxy) {
      props.api.setGridOption("columnDefs", getColDefs(tableProxy.columnArray));
    }
  };

  const handleRemoveColumn = () => {
    reqPage.removeColumn(tableColumn.id);
  };

  const handleHideColumn = () => {
    api.setColumnsVisible([column], !column.isVisible());
    reqPage.toggleColumn(tableColumn.id);
  };

  const renderHeaderNav = () => {
    const { entity, metaColumn } = tableColumn;
    if (entity?.type === EntityType.StatusDefinition) {
      const statusDefinition = workspace.statusDefinitionMap.get(entity.id);
      return (
        <Menu>
          <HeaderStatusNav
            hideErase
            onStatusTypeChange={handleStatusTypeChange}
            statusDefinition={statusDefinition}
            onDelete={() => setShowConfirmation(true)}
            onRemove={handleRemoveColumn}
            onHide={handleHideColumn}
          />
        </Menu>
      );
    } else if (metaColumn) {
      return (
        <Menu>
          <MenuItem onClick={handleHideColumn} icon="eye-off" text="Hide" e2eIdentifiers="hide" />
        </Menu>
      );
    }

    return undefined;
  };

  const renderStatusDisplayName = (entity: ITableColumnEntity) => {
    const statusDefinition: IStatusDefinition | undefined = workspace.statusDefinitionMap.get(entity.id);

    const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
      setIsEditing(false);
      statusDefinition?.setLabel(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case ENTER_KEY:
          setIsEditing(false);
          statusDefinition?.setLabel(e.currentTarget.value);
          break;
        case ESCAPE_KEY:
          setIsEditing(false);
          break;
      }
    };

    if (isEditing) {
      return (
        <InputGroup
          inputClassName={styles.requirementsHeaderCellRendererInput}
          defaultValue={statusDefinition?.label}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          e2eIdentifiers={["status-header", statusDefinition?.label ?? ""]}
          autoFocus
        />
      );
    }

    return <div onDoubleClick={() => setIsEditing(true)}>{statusDefinition?.label}</div>;
  };

  const renderDisplayName = () => {
    if (isCustomColumn) {
      return renderStatusDisplayName(entity);
    }

    return displayName;
  };

  return (
    <div className={styles.requirementsHeaderCellRenderer} onDoubleClick={isCustomColumn ? () => setIsEditing(true) : undefined}>
      <Text variant={TextVariant.H5} clamp={1}>
        {renderDisplayName()}
      </Text>
      <Popover position="bottom-left" content={renderHeaderNav()}>
        <Button minimal small icon={<Icon icon="drag-handle-vertical" size={12} />} e2eIdentifiers={["header-cell", "default"]} />
      </Popover>
      <DeleteConfirmationDialog
        isOpen={showConfirmation}
        titleItem="definition from the system"
        description="Are you sure you want to delete this definition? It will remove all related instances!"
        onCancel={() => setShowConfirmation(false)}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleDeleteDefinition}
      />
    </div>
  );
};

export default observer(RequirementsHeaderCellRenderer);
